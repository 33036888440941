import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DigitalService } from '../digital.service';
import { Message } from '../Message';
import { MessageResponse } from '../MessageResponse';
import { Body } from '../Message';
import { ElementText } from '../Message';
import { startWith, switchMap } from 'rxjs/operators';
import { interval, Subscription, EMPTY } from 'rxjs';
import { Globals } from '../globals';
import { EngagementNotification } from '../EngagementNotification';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TokenService } from '../services/token.service';
declare function initializeRichText(): any;
declare function formatOutgoingMessage(): any;
declare function toggleTUIToolbarFromJS(): any;
declare function clearMessage(): any;
declare function convertToHTML(string): any;

// const linkRegx = /(\(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}\))/g
const linkRegx = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewInit {

  public latestMsg: string = '';
  public allMsgInOne = [];
  htm = '<a href="google.com">link</a>'

  counter = 0;
  // public senderName = '';
  // public senderName = this.getSenderName();
  public senderName = "a";
  public senderId = this.digital.getCustomerIdentifier();
  messagesSubscription: Subscription;
  notificationSubscription: Subscription;
  public msgsflag = false;
  public messageFormat: string = 'PLAINTEXT';
  dateTime: Date = new Date();
  status = '';
  isAgentConnected=false;
  isMinimzed = false
  msgNative = '';

  constructor(public digital: DigitalService, public dialog: MatDialog, private tokenService:TokenService) {

  }
  ngOnInit() {
    this.subscribeToNotifications();
    // console.log('Chat initialized');
    // console.log('senderName = ' + this.senderName);
    this.getSenderName();
  }

  ngAfterViewInit() {
    // initializeRichText();
  }

   linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
}
  public terminateEngagement() {
    if (this.digital.getStatus() == 'Chat End') {
      this.digital.setEngagement(null);
      this.digital.setChatStatus(false);
      return;
    }
    this.digital.terminateEngagement()
      .subscribe((result) => {
        console.log('Enagement terminated');
        this.digital.setChatStatus(false);
        this.digital.setEngagement(null);
      });
  }

  public getStatus(): string {
    return this.digital.getStatus();
  }

  
  public minimize(): void {
    this.isMinimzed=!this.isMinimzed;
  }

  checkContainsLink(str){
    return linkRegx.test(str)
  }

  public getSenderName(): string {
   
   return this.digital.getCustomerSenderName();
  }

  // public sendMessage(): void {
  //   // console.log("Message body typed by user: " + this.latestMsg);
  //   let elementText = new ElementText(this.latestMsg, "PLAINTEXT");
  //   let body = new Body("text", elementText, "payload");
  //   let message = new Message(this.digital.getSessionId(), this.digital.getDialogId(), "correlationId", this.digital.getEngagementId(), this.digital.getCustomerIdentifier(), this.digital.getCustomerIdentifier(), "CUSTOMER", "parentMessageId", body, "providerParentMessageId", "CHAT", "channelProviderId");
  //   // console.log("Message object formed with engagementId: " + message.engagementId + " & dialogId: " + message.dialogId + " & message body text: " + message.body.elementText.text);
  //   console.log(body , message,'message');
  //   let response = this.digital.sendMessage(message)
  //     .subscribe((result) => {
  //       // console.log("Message sent : " + JSON.stringify(result));
  //       this.printGetMessage(result);
  //     });
  //   // console.log("Exiting sendMessage");
  //   this.latestMsg = "";
  // }

//   public sendMessageRichText(): void {
//     this.latestMsg = formatOutgoingMessage();
//     if (this.latestMsg != null && this.latestMsg != ""){
//     // console.log("Message body typed by user: " + this.latestMsg);
//     let elementText = new ElementText(this.latestMsg, this.messageFormat);
//     // console.log("Element Text Object formed: " + JSON.stringify(elementText));
//     let body = new Body("text", elementText, "payload");
//     // console.log("Body Object formed: " + JSON.stringify(body));
//     let message = new Message(this.digital.getSessionId(), this.digital.getDialogId(), "correlationId", this.digital.getEngagementId(), this.digital.getCustomerIdentifier(), this.digital.getCustomerIdentifier(), "CUSTOMER", "parentMessageId", body, "providerParentMessageId", "CHAT", "channelProviderId");
//     // console.log("Message object formed with engagementId: " + message.engagementId + " & dialogId: " + message.dialogId + " & message body text: " + message.body.elementText.text);
//   console.log(this.latestMsg,elementText,'body of message')
//     let response = this.digital.sendMessage(message)
//       .subscribe((result) => {
//         // console.log("Message sent : " + JSON.stringify(result));
//         this.printGetMessage(result);
//       });
//     // console.log("Exiting sendMessage");
//     this.latestMsg = "";
//     clearMessage();
//   }
// }
  
  sendNativeMsg(){
     if (this.msgNative != null && this.msgNative != ""){
      let elementText = new ElementText(this.msgNative, this.messageFormat);
      let body = new Body("text", elementText, "payload");
    // console.log("Body Object formed: " + JSON.stringify(body));
    let message = new Message(this.digital.getSessionId(), this.digital.getDialogId(), "correlationId", this.digital.getEngagementId(), this.digital.getCustomerIdentifier(), this.digital.getCustomerIdentifier(), "CUSTOMER", "parentMessageId", body, "providerParentMessageId", "CHAT", "channelProviderId");
    // console.log("Message object formed with engagementId: " + message.engagementId + " & dialogId: " + message.dialogId + " & message body text: " + message.body.elementText.text);
    let response = this.digital.sendMessage(message)
      .subscribe((result) => {
        // console.log("Message sent : " + JSON.stringify(result));
        this.printGetMessage(result);
      });
    // console.log("Exiting sendMessage");
    this.msgNative = "";
    // clearMessage();
  } 
  }

  // handleTextAreaChange(e){
  //   this.msgNative = 
  // }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.msgNative = `${this.msgNative}${pastedText}`;
  }

  public smrtOnCtrlEnter(e): void {
    // console.log(e);
  if (e.keyCode === 13 && !e.shiftKey) {
    this.sendNativeMsg()
    e.preventDefault()
    return;
  }
    if(e.keyCode === 13  && e.shiftKey){
      e.preventDefault()
      this.msgNative = `${this.msgNative} \n`;
      return;
    }

    // this.msgNative = e.target.value
  }

  setMsgNative(e){
    // console.log(e , 'in setMsgNative')
    this.msgNative = e.target.value
    // 
  }
  
  public toggleTUIToolbar(): void {
	// console.log("initiating toggle of editor toolbar");
	toggleTUIToolbarFromJS();
  }

  public printGetMessage(message): void {
    // console.log("Message received : " + JSON.stringify(message) + " :: MessageBodyText : " + message.body.elementText.text);
  }

  subscribeToMessages(): void {
    // console.log("Entered subscribe to message method, starting getMessage poller");

    if (this.digital.getChatStatus)
      this.msgsflag = true;
    
    this.messagesSubscription = this.digital.getMessage()
      .subscribe((results: MessageResponse[]) => {

    if (this.digital.getChatStatus) {
      // console.log(JSON.stringify(results));
		  if (results != null) {
			  results.forEach(result => {
				if (results[this.counter] != null) {
				  // console.log(results[this.counter]);

				  if("MARKDOWN" === results[this.counter].body.elementText.textFormat.toUpperCase()) {
					results[this.counter].body.elementText.text = convertToHTML(results[this.counter].body.elementText.text);
				  }

				  this.dateTime.setTime(results[this.counter].receivedTimestamp);
				  var dateString = this.dateTime.toDateString();
				  var messageTitle = "<em> <strong>" + results[this.counter].senderName + "</strong>" + " | "
					+ ((dateString === new Date().toDateString()) ? "" : (this.dateTime.toLocaleDateString() + ", ")) + this.dateTime.toLocaleTimeString() + "</em>";

				  results[this.counter].senderName = messageTitle;

				  this.allMsgInOne.push(results[this.counter]);
				  // console.log(this.digital.getCustomerIdentifier());
				  this.counter = this.counter + 1;
				  // console.log("counter is " + this.counter);

				}

			  })
			}
		}
      }, error => {
        // console.log("No Msgs found", error);

      });
  }
  
  public setStatus(status: string): void {
    this.status = status;
  }

  subscribeToNotifications(): void {

    let subscription = interval(3000);
    this.notificationSubscription =
      subscription.pipe(
        startWith(0),
        switchMap(() => this.digital.getNotifications())
      )
        .subscribe((results: EngagementNotification[]) => {
          // console.log("notifications response - " + JSON.stringify(results));
          if (results == null) { return null; }
          // console.log(JSON.stringify(results));
          results.forEach(result => {
            switch (result.event) {
              case 'PARTICIPANT_ADDED': {
                this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
                if (result.participantType == "CUSTOMER" && this.isAgentConnected==false) {
                  this.digital.setStatus('Waiting for agent');
                  this.setStatus('Waiting for agent');
                }
                else if (result.participantType == "CUSTOMER" && this.isAgentConnected==true) {
                  // console.log("Agent connected flag is true. Not changing the status or color of bulb.");
                }
                else if (result.participantType == "AGENT") {
                  
                  this.digital.setStatus('Agent Connected');
                  this.setStatus('Agent Connected');
                  this.isAgentConnected = true;
                }
                this.subscribeToMessages();

                break;
              }

              case 'MATCH_QUEUED': {
                if (this.isAgentConnected == false){
                this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
                this.digital.setStatus('Waiting for agent');
                this.setStatus('Waiting for agent');
                } 
                else if (this.isAgentConnected == true){
                  // console.log("Agent connected flag is true. Not changing the status or color of bulb.");
                }

                break;
              }
              case 'PARTICIPANT_DISCONNECTED': {
               if (result.displayName === this.getSenderName()){
                // window.alert("############################################"+ this.getSenderName() + "#########"+result.displayName);
                  this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
                  this.digital.setPayload('av-state-away');
                  this.digital.setStatus('Chat End');
                  this.setStatus('Chat Terminated');
               }
               else{
               // window.alert("############################################"+ this.getSenderName() + "#########"+result.displayName);

                this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
                this.digital.setPayload('av-state-away');
                this.digital.setStatus('Waiting for agent');
                this.setStatus('Waiting for agent');
               }
                
                
                break;
              }
              case 'ENGAGEMENT_TERMINATED': {
                
                this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
                this.digital.setPayload('av-state-offline');
                this.digital.setStatus('Chat End');
                this.setStatus('Chat Terminated');

                break;
              }
              case 'ERROR_NOTIFICATION': {
                if (new Date(result.createTimeMillis) > this.digital.getCreated()) {
                  this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
                  this.digital.setStatus('Error');
                  let dialogRef = this.dialog.open(DialogComponent, {
                    data: {
                      name: 'error',
                      message: result.errorNotification.errorCode
                    },
                    height: '200px',
                    width: '300px',
                  });

                }

              }

            }

          }
          );
        },(error) => {
          console.log("error in notification : " + error);
          this.tokenService.generateToken(this.digital.getkeycloakServerUrl());
          this.subscribeToNotifications();
        })

  }

  ngOnDestroy() {
    this.messagesSubscription.unsubscribe();
    this.notificationSubscription.unsubscribe();
  }

}
