export class Message {
    // private Message = {};
    constructor(
        public sessionId: string,
        public dialogId: string,
        public correlationId: string,
        public engagementId: string,
        public senderId: string,
        public senderName: string,
        public senderType: string,
        public parentMessageId: string,
        public body: Body,
        public providerParentMessageId: string,
        public channelId: string,
        public channelProviderId: string
    ) { }
    // public setMessage(key: string, val: string) {
    //     this.Message[key] = val;
    // }
    // public setParams(param: any) {
    //     this.Message = param;
    // }
} 
export class Body {
    // private Body = {};
    constructor(
        public elementType : string,
        public elementText : ElementText,
        public payload : any
    ) { }
    // public setBody(key: string, val: string) {
    //     this.Body[key] = val;
    // }
    // public setParams(param: any) {
    //     this.Body = param;
    // }
}

export class ElementText {
    // private ElementText = {};
    constructor(
        public text : string,
        public textFormat : string
    ) { }
    // public setElementText(key: string, val: string) {
    //     this.ElementText[key] = val;
    // }
    // public setParams(param: any){
    //     this.ElementText = param;
    // }
}