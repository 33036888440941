import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {MatFormFieldModule, MatSelectModule, MatDialog, MatDialogRef} from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import {DigitalService} from './digital.service';
import { SessionCreateData } from './SessionCreateData';
import {SessionResponse} from './SessionResponse';

import { EngagementResponse } from './EngagementResponse';
import {interval} from 'rxjs';
import { startWith, switchMap, isEmpty, count } from 'rxjs/operators';
import {EngagementNotification} from './EngagementNotification';
import { MessageResponse } from './MessageResponse';
import { ChatComponent } from './chat/chat.component';
import { SelectionDialogComponent } from './selection-dialog/selection-dialog.component';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import {JoinEngagementRequest} from './JoinEngagementRequest'
import { JoinEngagementResponse } from './JoinEngagementResponse';
import { TokenService } from './services/token.service';
import { HttpClient } from '@angular/common/http';
import uuidv4 from './utils/uuidv4'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'] 
})
export class AppComponent implements OnInit, AfterViewInit  {

  ngOnInit(): void {
    // generate the unique id to talk to the getToken rest api

    let uuid = uuidv4();
    localStorage.setItem('subject',uuid);

    
    this.tokenService.subject.subscribe((res :{success:[string] , boolean})=>{
      this.userMenuDisabled = !res.success
    })
    // localStorage.clear();

    // let dialogRef = this.dialog.open(SelectionDialogComponent, {
    //   data: {
    //     name: 'ActiveEngagements',
    //     message: 'success',
    //     engagementList: [
    //       {engagementId:1,conversation:'For Demo Purposes'} , {engagementId:2,conversation:'For Demo Purposes 2'}],
    //     title: 'Active Chats'
    //   },
    //   height: 'auto',
    //   width: '300px'

    // }
    // );

    var savedLanguage = localStorage.getItem('savedLanguage');
    if (null !== savedLanguage) {
      this.lang = savedLanguage;
    }
  }

  ngAfterViewInit(): void {
    
  }
  
  @ViewChild(ChatComponent, {
    static: true
  })
  chatcomponent: ChatComponent;
  
  lang:string;
  title = 'Avaya CC NXT';
  loadSettingsObject = false;
  lastReadMessage='';
  counter=0;
  newMessage='';
  public allMsgInOne =[];
  public activeEngagements:EngagementResponse[];
  public ShowSignIn = false;
  public userMenuDisabled = true;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer, public dialog: MatDialog,
    public digital: DigitalService,
    private tokenService: TokenService,
    private http: HttpClient) {

    this.matIconRegistry.addSvgIcon(
      'user',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/baseline-account_circle-24px.svg')
    );
  }
  
 

  // This is what calls the getActiveEngagements after session creation
  createSession(): void{
    
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {
        name: 'createSession'
      },
      height: 'auto',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('here after closed')
     var sessionData  = new SessionCreateData(result.accountId, result.customerIdentifier, result.displayName, result.firstName, result.lastName, result.emailAddress, result.contactNumber,result.providerId, 
      '',
      '',
      ''
      );
     sessionData.setParams(result.sessionParameters)
     if(this.lang)
     {
      sessionData.setSessionParameters("language", this.lang);
     }

     console.log('eeeeeeeeeeeee')
     
     this.digital.createSession(sessionData)
      .subscribe((result: SessionResponse) => {
        // console.log(result,'sessoin create res');
        this.digital.setSessionResponse(result);
        this.getActiveEngagements();
        
      });
      // console.log('Session created');
    })
  }

  
  activateChat():void{
    console.log('Chat requested');
    this.digital.createEngagement()
    .subscribe((result:EngagementResponse) =>{
      // console.log("Engagement Created" + JSON.stringify(result));
      this.digital.setEngagement(result);
      //this.chat = true;
      this.digital.setChatStatus(true);
      
    //  this.subscribeToNotifications();
    //  this.chatcomponent.subscribeToMessages();

    }, error =>{
        let dialogRef = this.dialog.open(DialogComponent, {
          data:{
            name: 'error',
            message: error.error.message
          },
            height: '200px',
            width: '300px',
        });
    })
    // console.log("Engagement created");
  }

  terminateSession():void{

    if(this.digital.getDialogId()){

      this.digital.terminateEngagement()
      .subscribe((result) => {
        console.log('Enagement terminated');
        this.digital.setChatStatus(false);
        this.digital.setEngagement(null);


        this.digital.terminateSession()
        .subscribe((result:any) => {
          // console.log('Session terminated');
          this.digital.setSessionResponse(null);
       });
     
      });
    
    } else {
      this.digital.terminateSession()
      .subscribe((result:any) => {
        // console.log('Session terminated');
        this.digital.setSessionResponse(null);

        this.digital.setChatStatus(false);
        this.digital.setEngagement(null);
     });
    }
    
  }
  
  
  subscribeToNotifications():void{
    
    let subscription = interval(1000);
    subscription.pipe(
      startWith(0), 
      switchMap(() => this.digital.getNotifications())
    )
    .subscribe((results:EngagementNotification[]) => {
      // console.log(JSON.stringify(results));
      results.forEach(result =>{

        switch(result.event){
          case 'PARTICIPANT_ADDED':{
            this.chatcomponent.subscribeToMessages();
            break;
          }


          case 'participantJoined':{

            if(new Date(result.createTimeMillis) > this.digital.getCreated()) {
              this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
              this.digital.setPayload('av-state-connecting');
              this.digital.setStatus('Waiting for agent');
              if(result.participantJoined.participantType == 'AGENT')
              {
                this.digital.setPayload('av-state-online');
                this.digital.setStatus('connected');
                
                				  
              }
            }
              
              break;
          }
          case 'engagementQueued':{
            
            if(new Date(result.createTimeMillis) > this.digital.getCreated()) {
              this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
              this.digital.setPayload('av-state-away');
              this.digital.setStatus('Queued');
            }
            break;
        }
        case 'engagementEnded':{
          if(new Date(result.createTimeMillis) > this.digital.getCreated()) {
            this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
            this.digital.setPayload('av-state-online');
            this.digital.setStatus('Chat End');
          }
          
          break;
         }
         case 'errorNotification':{
          if(new Date(result.createTimeMillis) > this.digital.getCreated()) {
            this.digital.updateNofitifactionDate(new Date(result.createTimeMillis));
            this.digital.setStatus('Error');
            let dialogRef = this.dialog.open(DialogComponent, {
              data: {
                name: 'error',
                message: result.errorNotification.errorCode
              },
              height: '200px',
              width: '300px',
            });

          }
         
         }
  
        }

      });
    })
  }
  
// Logic will change
  existingEngagmentsDialog(engagements: EngagementResponse[]) {
    let dialogRef = this.dialog.open(SelectionDialogComponent, {
      data: {
        name: 'ActiveEngagements',
        message: 'success',
        engagementList: engagements,
        title: 'Active Chats'
      },
      height: 'auto',
      width: '300px',
      disableClose:true

    }
    );
  }

//logic will change
  getActiveEngagements(): void {
    // alert('hereee')
  //  console.log('Chat requested');
    this.digital.getEngagements().subscribe((result: EngagementResponse[]) => {
      // console.log("************** getActiveEngagements*************" + result);
      this.ShowSignIn = true;
      if (result != null) {
        // terminate old engagments
        result.forEach((engagment)=>{
          this.digital.terminateEngagement(engagment.engagementId , engagment.dialogs[0].dialogId).subscribe((data)=>{}
          ,(err)=>{
            console.log(err)
          }
          )
        })
        this.activateChat();
        // this.existingEngagmentsDialog(result);
      }
    }, error => {
      // console.log("No Engagements found", error);
    })
    // console.log("Get engagements request made");
  }

  getChatStatus():boolean{
    // console.log("App component get status-"+this.digital.getChatStatus());

     return this.digital.getChatStatus();
    
  }
}
