// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export interface KeycloakConfig {
  realm: string;
  clientId: string;
  clientSecret: string;
  grantType: string;
}

// Add here your keycloak configuration information
// const keycloakConfig: KeycloakConfig = {
//   realm: 'system',
//   clientId: 'bdd',
//   clientSecret: 'bdd_client_secret',
//   grantType: 'client_credentials'
// };

export const environment = {
  production: false,
  // keycloakConfig
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
