import { Component, OnInit } from '@angular/core';
import {MatFormFieldModule, MatSelectModule, MatDialog} from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DigitalService } from '../digital.service';
import { Server } from 'selenium-webdriver/safari';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer, public dialog:MatDialog, private digitalService: DigitalService) {
    this.matIconRegistry.addSvgIcon(
    'settings',
    this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icon/baseline-settings-20px.svg')
  );
  
  }

  
  
  ngOnInit() {
    // if(!localStorage.getItem('serverSettings'))
      this.settings();
    // else{
      
    //}
  }

  settings():void{
    // console.log("Settings clicked");
    let dialogRef = this.dialog.open(DialogComponent, {
      data: {
        name: 'settings'
      },
      height: 'auto',
      width: 'auto',
    }); 
    dialogRef.afterClosed().subscribe(result => {
      // console.log('Settings Saved' + JSON.stringify(result),result);
      if(result.isSaved && result.proxy == 'no'){
        this.digitalService.saveSettings(result.server, result.port, result.protocol, result.messageurl , result.providerId, result.accountId) ;
       // debugger;
        // console.log(this.digitalService.getUrl());
      }
      else{
        this.digitalService.useProxy(result.messageurl);
        // console.log('Using proxy configuration to use pre configured Digital services');
      }
    })
  }
}
