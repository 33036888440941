import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DigitalService } from '../digital.service';
import { JoinEngagementRequest } from '../JoinEngagementRequest';
import { JoinEngagementResponse } from '../JoinEngagementResponse';
import { debug } from 'util';
import { EngagementResponse } from '../EngagementResponse';


@Component({
  selector: 'app-selection-dialog',
  templateUrl: './selection-dialog.component.html',
  styleUrls: ['./selection-dialog.component.css']
})
export class SelectionDialogComponent implements OnInit { 

  list:any 
  item:any
  constructor(private digital: DigitalService , 
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<SelectionDialogComponent>,    
    @Inject(MAT_DIALOG_DATA) public data: any) { }
public dialogId = '';
  ngOnInit() {
    // console.log('Selection dialog initialized');
    this.dialogRef.updatePosition({
      bottom:'10px',
      right : '6px'

    })
  }

// After clicking on one of the existing engagements
  getEngagement(engagementId: string): void {
    //  console.log('Get engagement for -'+engagementId);
    this.digital.getEngagement(engagementId).subscribe((result: EngagementResponse) => {
      // console.log("Engagement Created" + JSON.stringify(result));
      this.digital.setEngagement(result);
      // console.log("engagementId - ", this.digital.getEngagementId());
      // console.log("dialogId - ", result.dialogs[0].dialogId);
      this.dialogId = result.dialogs[0].dialogId;
      this.dialogRef.close();
      this.joinEngagement();      
    }, error => {
      // console.log("No Engagements found", error);
    })

    console.log("Get engagements request made");
  }


  joinEngagement(): void {
    let enableChat:boolean;
    // console.log('Chat window will be launched');
    // console.log('values to be fed into Join engagement request: ' + this.digital.getSessionId() + '::' + this.digital.getEngagementId() + '::' + this.dialogId + '::' + this.digital.getCustomerIdentifier());
    let joinEngagementRequest = new JoinEngagementRequest(this.digital.getSessionId(), this.digital.getEngagementId(), this.dialogId, this.digital.getCustomerIdentifier());
    this.digital.joinEngagement(joinEngagementRequest, this.digital.getEngagementId()).subscribe((result: JoinEngagementResponse) => {
    // console.log("Engagement joined" + JSON.stringify(result));
    this.digital.setChatStatus(true);
    // console.log(this.digital.getChatStatus());   
    }, error => {
      // console.log("Unable to join", error);
    })
    // console.log("Engagement joined");
  }

  

}
