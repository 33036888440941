
export class SessionCreateData {
    public  sessionParameters:any = {};
    constructor(
        public accountId: string,
        public customerIdentifier: string, 
        public displayName: string,
        public firstName: string,
        public lastName: string,
        public emailAddress: string,
        public contactNumber: string,
        public providerId : string,
        public availableBalance : string,
        public customerType : string,
        public sourcePage : string,


    ){}
    public setSessionParameters(key:string, val:string){
        this.sessionParameters[key] = val;
    }
    public setParams(param:any){
        this.sessionParameters = param;
    }
}