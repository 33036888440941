import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { DigitalService } from '../digital.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private tokenService:TokenService, private digitalService:DigitalService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status)) {
                // refresh token if 401 or 403 response returned from api
                this.tokenService.generateToken(this.digitalService.getkeycloakServerUrl());
            }
            
            return next.handle(request);
        }))
    }
}