export var ConfigJson={

    "env":[
        {
            "displayName":"Experience1.avaya.com",
            "accountId":"HNDJMB",
        },
        {
            "displayName":"Experience2.avaya.com",
            "accountId":"HECVCD"
        },
        {
            "displayName":"Experience3.avaya.com",
            "accountId":"IQFNDM"
        },
        {
            "displayName":"Experience4.avaya.com",
            "accountId":"OTYXBC"
        },
        {
            "displayName":"UAT",
            "accountId":"VAWTYV"
        }

    ],
    "lang":[
        "Chinese",
        "English",
        "French",
        "German",
        "Hindi",
        "Japanese",
        "Russian",
        "Spanish"
    ]
  
}