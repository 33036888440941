import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { KeycloakConfig } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) { }

  subject = new Subject();

  generateToken(KeycloakServerUrl: string): void {
    
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

   // debugger;

    this.http.post<any>('https://ccaas-cc-chat.experience.avaya.com/getToken',{subject: localStorage.getItem('subject'),base_url:KeycloakServerUrl})
      .subscribe(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', JSON.parse(JSON.stringify(data.token)));
        this.subject.next({success:true})
      });
  }
}
