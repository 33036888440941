
export class JoinEngagementRequest {
    private JoinEngagementRequest = {};
    constructor(
        public sessionId: string,
        public engagementId: string,
        public dialogId: string,
        public customerIdentifier: string


    ) { }

} 